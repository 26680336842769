@font-face {
	font-family: 'Bebas Neue Bold';
	src: url('BebasNeue-Bold.otf');
	font-weight: 700;
	font-style: normal;
}

.font-bebas {
	font-family: 'Bebas Neue Bold';
	font-weight: 700;
}
