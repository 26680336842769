.project-link-active > button,
.project-link-active > button > span {
	background: white;
	color: #222;
}

.instagram-icon {
	background-image: url('../../../assets/icons/instagram.svg');
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center center;
	cursor: pointer;
}
.facebook-icon {
	background-image: url('../../../assets/icons/facebook.svg');
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center center;
	cursor: pointer;
}
.website-icon {
	background-image: url('../../../assets/icons/website.svg');
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center center;
	cursor: pointer;
}
