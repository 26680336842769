.font-cera {
	font-family: 'Cera Pro';
}

@font-face {
	font-family: 'Cera Pro';
	src: url('./Cera-Pro-Regular.otf') format('truetype');
	font-weight: 400;
}

@font-face {
	font-family: 'Cera Pro';
	src: url('./Cera-Pro-Thin.otf') format('truetype');
	font-weight: 200;
}

@font-face {
	font-family: 'Cera Pro';
	src: url('./Cera-Pro-Light.otf') format('truetype');
	font-weight: 300;
}

@font-face {
	font-family: 'Cera Pro';
	src: url('./Cera-Pro-Black.otf') format('truetype');
	font-weight: 700;
}

@font-face {
	font-family: 'Cera Pro';
	src: url('./Cera-Pro-Bold.otf') format('truetype');
	font-weight: 600;
}

@font-face {
	font-family: 'Cera Pro';
	src: url('./Cera-Pro-Medium.otf') format('truetype');
	font-weight: 500;
}
