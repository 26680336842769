.fade-to-right {
	-webkit-animation: fadeRight 0.3s; /* Safari, Chrome and Opera > 12.1 */
	-moz-animation: fadeRight 0.3s; /* Firefox < 16 */
	-ms-animation: fadeRight 0.3s; /* Internet Explorer */
	-o-animation: fadeRight 0.3s; /* Opera < 12.1 */
	animation: fadeRight 0.3s;
	animation-fill-mode: forwards;
}

@keyframes fadeRight {
	from {
		opacity: 0;
		transform: translateX(-40px);
	}
	to {
		opacity: 1;
		transform: translateX(0);
	}
}

/* Firefox < 16 */
@-moz-keyframes fadeRight {
	from {
		opacity: 0;
		transform: translateX(-40px);
	}
	to {
		opacity: 1;
		transform: translateX(0);
	}
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeRight {
	from {
		opacity: 0;
		transform: translateX(-40px);
	}
	to {
		opacity: 1;
		transform: translateX(0);
	}
}

/* Internet Explorer */
@-ms-keyframes fadeRight {
	from {
		opacity: 0;
		transform: translateX(-40px);
	}
	to {
		opacity: 1;
		transform: translateX(0);
	}
}

/* Opera < 12.1 */
@-o-keyframes fadeRight {
	from {
		opacity: 0;
		transform: translateX(-40px);
	}
	to {
		opacity: 1;
		transform: translateX(0);
	}
}
