.carousel {
	margin: auto;
	overflow: hidden;
	position: relative;
	max-width: 100vw;
}

.carousel > div.slider {
	position: absolute;
	display: flex;
	height: 100%;
	left: -100%;
	top: 0;
	width: 100%;
	will-change: contents, left;
	transition-property: left;
	flex-wrap: nowrap;
}

.carousel > div.slider > img {
	width: 100%;
}

.carousel > div.slider > img:first-child {
	z-index: 1;
}

.carousel > .prev,
.carousel > .next {
	bottom: 0;
	color: #fff;
	border: 0;
	outline: 0;
	cursor: pointer;
	font-weight: 700;
	height: 50px;
	line-height: 50px;
	margin-top: auto;
	margin-bottom: auto;
	position: absolute;
	text-align: center;
	top: 0;
	transition: all 225ms;
	width: 40px;
	z-index: 2;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center center;
}

.carousel:hover > .prev,
.carousel:hover > .next {
	color: rgba(255, 255, 255, 0.7);
}

.carousel > .prev:hover,
.carousel > .next:hover {
	color: rgba(255, 255, 255, 1);
}

.carousel > .prev.disabled,
.carousel > .next.disabled {
	color: rgba(255, 255, 255, 0.2);
}

.carousel > .prev {
	left: 0;
	background-image: url('../../assets/icons/prev.svg');
}

.carousel > .next {
	right: 0;
	background-image: url('../../assets/icons/next.svg');
}
