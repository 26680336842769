@import './fonts/bebasneue.css';
@import './fonts/cera.css';
@import './styles/index.css';

html,
body {
	scroll-behavior: smooth;
}

#root {
	min-height: 100vh;
}
img {
	max-width: unset;
}
