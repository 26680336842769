.fade-out {
	-webkit-animation: fadeOut 0.3s; /* Safari, Chrome and Opera > 12.1 */
	-moz-animation: fadeOut 0.3s; /* Firefox < 16 */
	-ms-animation: fadeOut 0.3s; /* Internet Explorer */
	-o-animation: fadeOut 0.3s; /* Opera < 12.1 */
	animation: fadeOut 0.3s;
	animation-fill-mode: forwards;
}

@keyframes fadeOut {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}

/* Firefox < 16 */
@-moz-keyframes fadeOut {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeOut {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}

/* Internet Explorer */
@-ms-keyframes fadeOut {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}

/* Opera < 12.1 */
@-o-keyframes fadeOut {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}
